import { forwardRef } from 'react'

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ScopedCssBaseline } from '@mui/material';

import { useAppSelector } from './app/appModule';
import { formatLightColor, formatDarkColor } from "./color"

//route
import {
    createBrowserRouter,
    RouterProvider,
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from "react-router-dom";
import { LinkProps } from '@mui/material/Link';

import Root from "./routes/root";
import Index from './routes';



const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <></>,
        children: [
            {
                errorElement: <></>,
                children: [
                    {
                        index: true, element: <Index />
                    },
                    { path: "/add", element: <></> }
                ]
            }
        ]
    },
]);

const LinkBehavior = forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
});


export default function App() {
    const colorMode = useAppSelector(state => state.app.colorMode)

    const theme = createTheme({
        palette: {
            ...(colorMode === 'light' ? {
                ...formatLightColor
            } :
                {
                    ...formatDarkColor
                }
            )
        },
        components: {
            // please ignore this error
            MuiLink: {
                defaultProps: {
                    component: LinkBehavior,
                } as LinkProps,
            },
            MuiButtonBase: {
                defaultProps: {
                    LinkComponent: LinkBehavior,
                },
            },
        },
    });


    return (
        <ThemeProvider theme={theme}>
            <ScopedCssBaseline>
                <RouterProvider router={router} />
            </ScopedCssBaseline>
        </ThemeProvider>
    )
}

