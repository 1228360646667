import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import RmamaLogo from '@/assets/Rmama-outline.svg?react';
import i18n from '@/constant/i18n'


import { Tooltip, Avatar } from '@mui/material'
import { useAppDispatch } from 'src/app/appModule';
import { appActions } from 'src/app/appSlice';


export default function Appbar() {
    const dispatch = useAppDispatch()
    const handleMenuIcon = () => {
        dispatch(appActions.switchDrawerState({}))
    }
    return (
        <AppBar position="fixed" sx={{ backgroundColor: (theme) => theme.palette.background.default, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={handleMenuIcon}
                >
                    <MenuIcon />
                </IconButton>
                <RmamaLogo style={{ height: "40px" }} />
                <Box>
                    <Tooltip title={i18n.STR_USER_SETTING}>
                        <IconButton sx={{ p: 0 }}>
                            <Avatar alt="Remy Sharp" src="/images/avatar/koala.png" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>
        </AppBar>
    );
}