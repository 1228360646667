import Div from "@/components/MuiCustomComponent/MuiDiv";
import { Toolbar, Typography } from "@mui/material";
import RmamaJump from '@/assets/Rmama-jump.svg';

export default function Index() {
    return (
        <Div sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, px: 5, py: 5, justifyContent: 'center', height: '100%' }}>
            <header>
                {/* Using <Toolbar /> to fill the header space */}
                <Toolbar />
            </header>

            <Div sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',flexDirection:'column' }}>
                {/* <RmamaJump/> */}
                <img src={RmamaJump} style={{width: '50%',maxWidth:'500px',height:'auto'}} />
                <Typography variant="h4" sx={{py:2,fontWeight:700}}>
                    Coming Soon...
                </Typography>
            </Div>

        </Div>
    );
}