import { SnackbarProps, Snackbar, Alert } from '@mui/material';
import { useState } from 'react';

export enum SNACKBAR_TYPE {
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error'
}

interface CustomSnackbarProps extends SnackbarProps {
    type?: SNACKBAR_TYPE;
    msg: React.ReactNode
}

export default function SnackbarStyled(props: CustomSnackbarProps) {
    const { type = SNACKBAR_TYPE.INFO, msg, open, onClose } = props

    return (
        <Snackbar open={open} onClose={onClose} autoHideDuration={1500} >
            <Alert
                severity={type}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {msg}
            </Alert>
        </Snackbar>
    )
}

export function useSnackbar(): [boolean, () => void, (_event: React.SyntheticEvent | Event, reason?: string) => void] {
    const [isOpen, setIsOpen] = useState(false);
    const openSnackbar = () => setIsOpen(true);
    const closeSnackbar = (_event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsOpen(false);
    };
    return [isOpen, openSnackbar, closeSnackbar];
}