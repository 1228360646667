import { styled,Box } from "@mui/material";


// because mui doesn't provide MuiBox to use.
// ref: https://stackoverflow.com/questions/69455056/override-box-component-in-createtheme
// const _Div = styled("div", {
//     name: "MuiDiv",
//     overridesResolver: (_props: any, styles: any) => {
//         return [styles.root];
//     }
// })();

const Div = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
}));


export default Div



