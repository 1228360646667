
import i18n from '@/constant/i18n';

import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import HomeIcon from '@mui/icons-material/Home';
import BookIcon from '@mui/icons-material/Book';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useAppDispatch, useAppSelector } from 'src/app/appModule';
import Div from './MuiCustomComponent/MuiDiv';
import { Divider, ListItemTextProps } from '@mui/material';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import SnackbarStyled, { SNACKBAR_TYPE, useSnackbar } from './MuiCustomComponent/SnackbarStyled';
import { appActions } from 'src/app/appSlice';
import RootSnackbar from './MuiCustomComponent/RootSnackbar';
import { useDispatch } from 'react-redux';

const drawerWidth = 200;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 85,
    // width: `calc(${theme.spacing(7)} + 1px)`,
    // [theme.breakpoints.up('sm')]: {
    //     width: `calc(${theme.spacing(8)} + 1px)`,
    // },
});

const drawerListPrimary = [
    { key: 'home', label: i18n.STR_DRAWER_HOME, icon: <HomeIcon /> },
    { key: 'diary', label: i18n.STR_DRAWER_DIARY, icon: <BookIcon /> },
    { key: 'guess', label: i18n.STR_DRAWER_GUESS, icon: <AttachMoneyIcon /> },
    { key: 'roll', label: i18n.STR_DRAWER_ROLL, icon: <EmojiEventsIcon /> },
    { key: 'more', label: i18n.STR_DRAWER_MORE, icon: <MoreHorizIcon /> },
]
const drawerListSecondary = [
    { key: 'common', label: i18n.STR_DRAWER_COMMON, icon: <HelpOutlineIcon /> },
    { key: 'support', label: i18n.STR_DRAWER_SUPPORT, icon: <SupportAgentIcon /> }
]


const DrawerStyled = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        whiteSpace: 'nowrap',
        oxSizing: 'border-box',
        flexShrink: 0,
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const ListItemStyled = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(3, 1),
    height: 36
}));

interface CustomListItemTextProps extends ListItemTextProps {
    open: boolean;
}

const ListItemTextStyled = styled(({ open, ...other }: CustomListItemTextProps) => (
    <ListItemText {...other} />
))(({ theme, open }) => ({
    textWrap: 'nowrap',
    overflowX: 'hidden',
    opacity: open ? 1 : 0,
    transform: open ? 'translateX(0)' : 'translateX(-20px)',
    transition: theme.transitions.create(['opacity', 'transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
}));


export default function AppDrawer() {
    const [isOpenHome, openHome, closeHome] = useSnackbar();
    const [isOpenDiary, openDiary, closeDiary] = useSnackbar();
    const [isOpenGuess, openGuess, closeGuess] = useSnackbar();
    const handlers = {
        handleHome: {
            open: openHome,
            close: closeHome
        },
        handleDiary: {
            open: openDiary,
            close: closeDiary
        },
        handleGuess: {
            open: openGuess,
            close: closeGuess,
        }


    }
    const Snackbars = () => (
        <>
            <SnackbarStyled type={SNACKBAR_TYPE.INFO} msg={'Home'} open={isOpenHome} onClose={handlers.handleHome.close} />
            <SnackbarStyled type={SNACKBAR_TYPE.SUCCESS} msg={'Diary'} open={isOpenDiary} onClose={handlers.handleDiary.close} />
            <SnackbarStyled type={SNACKBAR_TYPE.WARNING} msg={'Guess'} open={isOpenGuess} onClose={handlers.handleGuess.close} />
        </>
    )
    return (
        <>
            <DesktopDrawer handlers={handlers} />
            <MobileDrawer handlers={handlers} />
            <RootSnackbar />
            <Snackbars />
        </>
    )
}

interface handleSnackbar {
    open: () => void;
    close: (_event: React.SyntheticEvent | Event, reason?: string) => void;
}
interface DrawProps {
    handlers: { handleHome: handleSnackbar, handleDiary: handleSnackbar, handleGuess: handleSnackbar }
}

function DesktopDrawer(props: DrawProps) {
    const isDrawerOpen = useAppSelector(state => state.app.isDrawerOpen)
    const dispatch = useDispatch()
    const { handlers } = props
    const mapHandle = {
        [drawerListPrimary[0].key]: handlers.handleHome.open,
        [drawerListPrimary[1].key]: handlers.handleDiary.open,
        [drawerListPrimary[2].key]: handlers.handleGuess.open,
        [drawerListPrimary[3].key]: () => {
            dispatch(appActions.setSnackbarInfo({ type: SNACKBAR_TYPE.INFO, msg: "抽獎" }));
            dispatch(appActions.setSnackbarIsOpen(true));
        },
        [drawerListPrimary[4].key]: () => {
            dispatch(appActions.setSnackbarInfo({ type: SNACKBAR_TYPE.ERROR, msg: "更多功能" }));
            dispatch(appActions.setSnackbarIsOpen(true));
        },
        [drawerListSecondary[0].key]: () => {
            dispatch(appActions.setSnackbarInfo({ type: SNACKBAR_TYPE.SUCCESS, msg: "常見問題" }));
            dispatch(appActions.setSnackbarIsOpen(false));
            dispatch(appActions.setSnackbarIsOpen(true));
        },
        [drawerListSecondary[1].key]: () => {
            dispatch(appActions.setSnackbarInfo({ type: SNACKBAR_TYPE.WARNING, msg: "客服支援" }));
            dispatch(appActions.setSnackbarIsOpen(false));
            dispatch(appActions.setSnackbarIsOpen(true));
        },
    }
    return (
        <DrawerStyled
            className="desktop-drawer"
            variant="permanent"
            open={isDrawerOpen}
            sx={{ display: { xs: 'none', sm: 'block' } }}
        >
            {/* Using <Toolbar /> to fill the header space */}
            <Toolbar />
            <Div sx={{ height: '100%', overflow: 'auto' }}>
                <List>
                    {drawerListPrimary.map((item, _index) => {
                        const handleListItem = () => {
                            if (mapHandle[item.key]) {
                                mapHandle[item.key]()
                            }
                        }
                        return (
                            <ListItemStyled key={item.label} >
                                <ListItemButton onClick={handleListItem}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    {/* <ListItemText primary={item.label} /> */}
                                    <ListItemTextStyled primary={item.label} open={isDrawerOpen} />
                                </ListItemButton>
                            </ListItemStyled>
                        )
                    })}
                </List>
                <Divider />
                <List>
                    {drawerListSecondary.map((item, _index) => {
                        const handleListItem = () => {
                            if (mapHandle[item.key]) {
                                mapHandle[item.key]()
                            }
                        }
                        return (
                            <ListItemStyled key={item.label} >
                                <ListItemButton onClick={handleListItem}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    {/* <ListItemText primary={item.label} /> */}
                                    <ListItemTextStyled primary={item.label} open={isDrawerOpen} />
                                </ListItemButton>
                            </ListItemStyled>
                        )
                    })}
                </List>

            </Div>
        </DrawerStyled>
    )
}

function MobileDrawer(props: DrawProps) {
    const isDrawerOpen = useAppSelector(state => state.app.isDrawerOpen)
    const dispatch = useAppDispatch()
    const { handlers } = props
    const mapHandle = {
        [drawerListPrimary[0].key]: handlers.handleHome.open,
        [drawerListPrimary[1].key]: handlers.handleDiary.open,
        [drawerListPrimary[2].key]: handlers.handleGuess.open,
    }
    return (
        <Drawer
            className="mobile-drawer"
            variant="temporary"
            open={isDrawerOpen}
            onClose={() => { dispatch(appActions.setDrawerState(false)) }}
            sx={{ display: { xs: 'block', sm: 'none' } }}
        >
            {/* Using <Toolbar /> to fill the header space */}
            <Toolbar />
            <Div sx={{ height: '100%', overflow: 'auto' }}>
                <List>
                    {drawerListPrimary.map((item, _index) => {
                        const handleListItem = () => {
                            if (mapHandle[item.key]) {
                                mapHandle[item.key]()
                            }
                        }
                        return (
                            <ListItemStyled key={item.label} >
                                <ListItemButton onClick={handleListItem}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    {/* <ListItemText primary={item.label} /> */}
                                    <ListItemTextStyled primary={item.label} open={isDrawerOpen} />
                                </ListItemButton>
                            </ListItemStyled>
                        )
                    })}
                </List>
                <Divider />
                <List>
                    {drawerListSecondary.map((item, _index) => (
                        <ListItemStyled key={item.label} >
                            <ListItemButton>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                {/* <ListItemText primary={item.label} /> */}
                                <ListItemTextStyled primary={item.label} open={isDrawerOpen} />
                            </ListItemButton>
                        </ListItemStyled>
                    ))}
                </List>

            </Div>
        </Drawer>
    )
}