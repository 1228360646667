import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { PaletteMode } from '@mui/material'
import { SNACKBAR_TYPE } from '@/components/MuiCustomComponent/SnackbarStyled'

interface SnackbarOperation {
    open: ()=>void;
    close: ()=>void;
}
interface SnackbarInfo {
    type: SNACKBAR_TYPE;
    msg: React.ReactNode;
}


const appInitState = {
    colorMode: 'light' as PaletteMode,
    isDrawerOpen: true,
    snackbar: {
        isOpen: false,
        type: SNACKBAR_TYPE.INFO,
        msg: null as React.ReactNode,
        open: () => {},
        close: () => {}
    }
}

export const appSlice = createSlice({
    name: 'app',
    initialState: appInitState,
    reducers: {
        setColorState: (state, action: PayloadAction<PaletteMode>) => {
            state.colorMode = action.payload
        },
        setDrawerState: (state, action: PayloadAction<boolean>) => {
            state.isDrawerOpen = action.payload
        },
        switchDrawerState: (state, _action) => {
            state.isDrawerOpen = !state.isDrawerOpen
        },
        setSnackbarOperation: (state,action:PayloadAction<SnackbarOperation>)=>{
            state.snackbar.open = action.payload.open;
            state.snackbar.close = action.payload.close;
        },
        setSnackbarIsOpen:(state, action: PayloadAction<boolean>)=>{
            state.snackbar.isOpen = action.payload
        },
        setSnackbarInfo: (state, action: PayloadAction<SnackbarInfo>) => {
            state.snackbar.type = action.payload.type;
            state.snackbar.msg = action.payload.msg;
        },

    }
})

export const appActions = appSlice.actions;
