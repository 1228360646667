
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Appbar from '@/components/Appbar';
import AppDrawer from '@/components/AppDrawer'
import Div from '@/components/MuiCustomComponent/MuiDiv';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { appActions } from 'src/app/appSlice';

enum PLATFORM {
    MOBILE = 0,
    DESKTOP = 1
}

function AppInitiator() {
    const dispatch = useDispatch();

    const platform = window.innerWidth <= 600 ? PLATFORM.MOBILE : PLATFORM.DESKTOP
    useEffect(() => {
        if (platform == PLATFORM.MOBILE) {
            dispatch(appActions.setDrawerState(false))
        }
        else if (platform == PLATFORM.DESKTOP) {
            dispatch(appActions.setDrawerState(true))
        }
        const snackbarOperation = {
            open: () => { dispatch(appActions.setSnackbarIsOpen(true)) },
            close: () => { dispatch(appActions.setSnackbarIsOpen(false)) }
        }
        dispatch(appActions.setSnackbarOperation(snackbarOperation))
    }
        , [dispatch])
    return null
}

export default function Root() {

    return (
        <>
            <AppInitiator />
            <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }} className="container">
                <Div className="header">
                    <Appbar />
                </Div>
                <Div sx={{ display: "flex", height: "100vh" }}>
                    <AppDrawer />
                    <Outlet />
                </Div>

            </Box>
        </>
    );
}