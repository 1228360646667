// https://www.da-vinci.com.tw/tw/blog/web_color_matching
// 主色：網頁中最主要的顏色，佔據版面最大面積（約50~60%）或是能見度較高的顏色都可以當作主色，能夠為網站配色奠定主要基調，影響想要傳遞的訊息與風格。
// 輔助色：輔助及襯托主色，且幫助主色建構更完整的形象，佔版面面積約30~40%左右，色彩選擇通常會比主色能見度較低一些，避免搶走主色的風采。
// 點綴色：通常版面的細節會使用點綴色來處理，面積佔比小於15%且散布在畫面中，能夠讓網頁配色的重點更加突出與醒目，適合刺激點擊的CTA配色使用。

// https://www.realtimecolors.com/?colors=333333-F9F5F3-D95A38-F2C94C-6C7A89&fonts=Poppins-Poppins
import type { PaletteMode } from '@mui/material'

const lightColor = {
    background: '#F9F5F3',
    primary: '#F6C795',
    contrastText: '#333333',
    text: '#333333',
    secondary: '#F2C94C',
    accent: '#3E4854',
    error: '#E74C3C',
    success: '#27AE60',
    textDim: '#707070',
    primaryShade: '#C04828',
    primaryGradient0: '#D95A38',
    primaryGradient1: '#F2C94C',
    secondaryShade: '#DABE76',
}
const darkColor = {
    background: '#0E0907',
    text: '#CCCCCC',
    primary: '#F6C795',
    secondary: '#F2C94C',
    accent: '#AAB4C0',
    error: '#E74C3C',
    success: '#27AE60',
    textDim: '#707070',
    primaryShade: '#C04828',
    primaryGradient0: '#D95A38',
    primaryGradient1: '#F2C94C',
    secondaryShade: '#DABE76',
}


const formatLightColor = {
    mode: 'light' as PaletteMode,
    background: {
        default: lightColor.background,
    },
    primary: {
        main: lightColor.primary,
        contrastText:lightColor.text,
    },
    secondary: {
        main: lightColor.secondary,
        contrastText:lightColor.textDim,
    },
    error: {
        main: lightColor.error,
    },
    success: {
        main: lightColor.success,
    },
    info: {
        main: lightColor.accent,
    }
}

const formatDarkColor = {
    mode: 'dark' as PaletteMode,
    background: {
        default: darkColor.background,
    },
    primary: {
        main: darkColor.primary,
        contrastText: darkColor.text,
    },
    secondary: {
        main: darkColor.secondary,
        contrastText: darkColor.textDim,
    },
    error: {
        main: darkColor.error,
    },
    success: {
        main: darkColor.success,
    },
    info: {
        main: darkColor.accent,
    }
}

export { lightColor, darkColor, formatLightColor, formatDarkColor }