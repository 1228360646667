const i18n = {
    "STR_USER_SETTING": "使用者設定",
    "STR_DRAWER_HOME":"首頁",
    "STR_DRAWER_DIARY":"食物日誌",
    "STR_DRAWER_GUESS":"猜食物價格",
    "STR_DRAWER_ROLL":"抽獎",
    "STR_DRAWER_MORE":"更多功能",
    "STR_DRAWER_COMMON":"常見問題",
    "STR_DRAWER_SUPPORT":"客服支援",
}


export default i18n